import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import { theme } from '../../../../domain/theme';
import { ContentMenu } from './components/ContentMenu';
import { SubscribeMenu } from './components/SubscribeMenu';
import { OtherSectionsMenu } from './components/OtherSectionsMenu';
import { Actionable } from '../../atoms/Actionable';
import { LegalHamburgerMenu } from './components/LegalHamburgerMenu';
import { IconSearch } from '../../../Icon/IconSearch';

export const HamburgerMenu = ({ closeCb }) => {
  const bgImageRef = useRef(null);

  const openSearch = () =>
    window.dispatchEvent(new CustomEvent('openSearch')) && closeCb();

  const styles = {
    container: css`
      position: relative;
      z-index: 2;
      overflow: hidden;
      padding: ${theme.spacing.parse('$xl3 $s $xl5 $s')};
      ${theme.mq.tablet} {
        padding: ${theme.spacing.parse('$xl3 $base $xl5 $base')};
      }
      ${theme.mq.small_desktop} {
        padding: ${theme.spacing('xl3')};
      }
    `,
    backgroundImageContainer: css`
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100vh;
      opacity: 0.35;
      z-index: 1;
      /* We need an actual image as a basis to get later transitions work */
      background-image: url(data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==);
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      box-shadow: inset 0 0 15em 3em #000;
      transition: background-image 0.35s ease-in-out;
    `,
    columnLeft: css`
      ${theme.mq.tablet} {
        float: left;
        width: 58%;
      }
    `,
    columnRight: css`
      ${theme.mq.tablet} {
        float: right;
        width: 40%;
      }
    `,
    searchIcon: css`
      position: absolute;
      top: ${theme.spacing('s')};
      left: ${theme.spacing('s')};
      ${theme.mq.tablet} {
        left: ${theme.spacing('base')};
      }
      ${theme.mq.small_desktop} {
        left: ${theme.spacing('xl3')};
      }
    `,
    hiddenInMobile: css`
      display: none;
      ${theme.mq.tablet} {
        display: block;
      }
    `,
    visibleInMobile: css`
      display: block;
      ${theme.mq.tablet} {
        display: none;
      }
    `
  };

  return (
    <>
      <div css={styles.backgroundImageContainer} ref={bgImageRef} />
      <div css={styles.container}>
        <div css={styles.searchIcon}>
          <Actionable onClick={openSearch}>
            <IconSearch color="#fff" />
          </Actionable>
        </div>
        <div css={styles.columnLeft}>
          <ContentMenu bgImageRef={bgImageRef} />
          <div css={styles.hiddenInMobile}>
            <SubscribeMenu />
          </div>
        </div>
        <div css={styles.columnRight}>
          <OtherSectionsMenu />
          <div css={styles.visibleInMobile}>
            <SubscribeMenu />
          </div>
          <LegalHamburgerMenu />
        </div>
      </div>
    </>
  );
};

HamburgerMenu.propTypes = {
  closeCb: PropTypes.func.isRequired
};
